export const breakpoints = {
  xs: [0, 575],
  sm: [576, 767],
  md: [768, 991],
  lg: [992, 1199],
  xl: [1200, Infinity]
}

export default {
  breakpoints
}
