var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sl-container',{staticClass:"background-img",attrs:{"id":"welcome","fullWidth":"","fullHeight":""}},[_c('Modal',[_c('div',{staticClass:"treehouse-badges"},_vm._l((_vm.treehouse.badges),function(ref){
var icon = ref.icon_url;
var name = ref.name;
var id = ref.id;
return _c('span',{key:id,staticClass:"treehouse-badge",attrs:{"data-tooltip":name}},[_c('img',{staticClass:"treehouse-badge__icon",attrs:{"alt":name,"src":icon}})])}),0)]),_c('sl-row',{staticClass:"wrapper",attrs:{"noGutters":"","flexGrow":1}},[_c('sl-col',{staticClass:"column--avatar",attrs:{"fullHeight":"","flex":"","align":"center","justify":"center","col":"12","md":"5","lg":"6"}},[_c('div',{directives:[{name:"lazy",rawName:"v-lazy.background-image",value:(_vm.avatarUrl),expression:"avatarUrl",modifiers:{"background-image":true}}],staticClass:"avatar"}),_c('div',{staticClass:"intro-text"},[_c('h4',{staticClass:"intro-text__name"},[_vm._v("Emily Rosina Carey")]),_c('h5',{staticClass:"intro-text__role"},[_vm._v("Front-end Developer")]),_c('h5',{staticClass:"intro-text__contributor"},[_c('span',{staticClass:"intro-text__contributor__text"},[_vm._v("Open Source contributor to")]),_c('span',{staticClass:"intro-text__contributor__count"},[_vm._v(_vm._s(_vm.noOfContributions))]),_c('p',{staticClass:"intro-text__contributor__wrapper"},_vm._l((_vm.contributions),function(contribution,key){return _c('a',{key:key,attrs:{"href":contribution.href,"target":"_blank"}},[_c('img',{staticClass:"intro-text__contributor__icon",attrs:{"src":contribution.icon}})])}),0)])]),_c('sl-row',{staticClass:"social-links"},_vm._l((_vm.socialLinks),function(ref,key){
var href = ref.href;
var target = ref.target;
var icon = ref.icon;
return _c('a',{key:key,staticClass:"social-link",attrs:{"href":href,"target":target}},[_c('icon',{attrs:{"name":icon,"scale":"1.5"}})],1)}),0),(false)?_c('icon',{staticClass:"clickable",attrs:{"name":"treehouse","scale":"3"},nativeOn:{"click":function($event){return _vm.OPEN_MODAL('TreeHouse Progress')}}}):_vm._e()],1),_c('sl-col',{staticClass:"column--repos",attrs:{"flex":"","align":"center","col":"12","md":"7","lg":"6"}},_vm._l((_vm.sortedRepos),function(repo,key){return _c('Repo',{key:key,attrs:{"repo":repo}})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }